// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { defineMessages } from 'react-intl'

import Breadcrumbs from '@ttn-lw/components/breadcrumbs'
import { useBreadcrumbs } from '@ttn-lw/components/breadcrumbs/context'
import Breadcrumb from '@ttn-lw/components/breadcrumbs/breadcrumb'

import IntlHelmet from '@ttn-lw/lib/components/intl-helmet'
import RequireRequest from '@ttn-lw/lib/components/require-request'

import PanelView from '@console/components/panel-view'

import Require from '@console/lib/components/require'

import UserManagement from '@console/views/admin-user-management'
import PacketBrokerRouter from '@console/views/admin-packet-broker'
import NetworkInformation from '@console/views/admin-panel-network-information'
import TenantSettings from '@console/views/admin-tenant-settings/index.tti'
import AlertingSettings from '@console/views/admin-alerting-settings/index.tti'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'
import { selectArsEnabled } from '@ttn-lw/lib/selectors/env'

import {
  checkFromState,
  mayConfigureAlertingSettings,
  mayConfigurePacketBroker,
  mayConfigureTenantSettings,
  mayManageUsers,
  mayPerformAdminActions,
} from '@console/lib/feature-checks'

import { getArsConfiguration } from '@console/store/actions/alert-routing-server.tti'

const m = defineMessages({
  peeringSettings: 'Peering settings',
})

const AdminPanel = () => {
  useBreadcrumbs(
    'admin-panel',
    <Breadcrumb path="/admin-panel" content={sharedMessages.adminPanel} />,
  )
  const showUserManagement = useSelector(state => checkFromState(mayManageUsers, state))
  const showPacketBroker = useSelector(state => checkFromState(mayConfigurePacketBroker, state))
  const showTenantSettings = useSelector(state => checkFromState(mayConfigureTenantSettings, state))
  const showAlertingSettings = useSelector(state =>
    checkFromState(mayConfigureAlertingSettings, state),
  )

  const fetchAdsConfiguration = useCallback(async dispatch => {
    if (selectArsEnabled()) {
      dispatch(attachPromise(getArsConfiguration()))
    }
  }, [])

  return (
    <Require featureCheck={mayPerformAdminActions} otherwise={{ redirect: '/' }}>
      <Breadcrumbs />
      <IntlHelmet title={sharedMessages.adminPanel} />
      <RequireRequest requestAction={fetchAdsConfiguration}>
        <PanelView>
          <PanelView.Item
            title={sharedMessages.networkInformation}
            icon="view_compact"
            path="network-information"
            Component={NetworkInformation}
            exact
          />
          {showTenantSettings && (
            <PanelView.Item
              title={sharedMessages.tenantSettings}
              icon="settings"
              path="tenant-settings"
              Component={TenantSettings}
              condition={showTenantSettings}
            />
          )}
          {showUserManagement && (
            <PanelView.Item
              title={sharedMessages.userManagement}
              icon="user_management"
              path="user-management"
              Component={UserManagement}
              condition={showUserManagement}
            />
          )}
          {showPacketBroker && (
            <PanelView.Item
              title={m.peeringSettings}
              icon="packet_broker"
              path="packet-broker"
              Component={PacketBrokerRouter}
              condition={showPacketBroker}
            />
          )}
          {showAlertingSettings && (
            <PanelView.Item
              title={sharedMessages.alertingSettings}
              icon="notifications"
              path="alerting-settings"
              Component={AlertingSettings}
              condition={showAlertingSettings}
            />
          )}
        </PanelView>
      </RequireRequest>
    </Require>
  )
}

export default AdminPanel
