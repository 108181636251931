// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useState, useCallback, useEffect } from 'react'
import { unstable_useBlocker } from 'react-router-dom'

import PortalledModal from '@ttn-lw/components/modal/portalled'

import PropTypes from '@ttn-lw/lib/prop-types'

const Prompt = ({ modal, children, message, when }) => {
  const [showModal, setShowModal] = useState(false)
  const blocker = unstable_useBlocker(when)

  const handleModalComplete = useCallback(
    approved => {
      setShowModal(false)
      if (approved) {
        blocker.proceed()
      }
    },
    [blocker],
  )

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (!when) {
        blocker.reset()
        return
      }
      setShowModal(true)
    }
  }, [blocker, when])

  return (
    <PortalledModal
      message={message}
      visible={showModal}
      {...modal}
      approval
      onComplete={handleModalComplete}
    >
      {children}
    </PortalledModal>
  )
}

Prompt.propTypes = {
  children: PropTypes.node,
  message: PropTypes.message.isRequired,
  modal: PropTypes.shape({ ...PortalledModal.Modal.propTypes }).isRequired,
  when: PropTypes.bool.isRequired,
}

Prompt.defaultProps = {
  children: undefined,
}

export default Prompt
